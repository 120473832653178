.image {
    /*fallback value for older browsers*/
    height: 100vh;
    height: 100svh;
    width: 100%;
    filter: brightness(50%);
    object-fit: cover;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}