.commonmodule {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 85px 20px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #f7f7f7;
}

.text {
    margin: 0 20px;
    max-width: 700px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.imagecropper {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.profilepic {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}