.commonmodule {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 85px 20px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fff;
}

.offercard {
    margin: 0 20px 60px;
}

.serviceitems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.serviceitem {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px 60px;
    max-width: 350px;
}

ul {
    list-style: none;
    margin: 20px;
    padding: 0;

    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

li {
    text-align: -webkit-match-parent;
}